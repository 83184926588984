<template>
  <div id="static">
    <div class="title">Privacy Policy</div>
    <div class="content">
      <p>
        This Policy establishes the specificities of data-processing of the Users of the Service
        located at
        <a href="https://la2dream.com/" target="_blank">https://la2dream.com/</a>
        (hereinafter referred to as the "Service") and the game servers (hereinafter referred to as
        "Servers"). This Policy also includes all applications and additions to it.
      </p>
      <p>
        This document (hereinafter referred to as the "Policy") is an integral part of the User
        Agreement of the Service.
      </p>
      <p>
        The text of this document is available at
        <a href="https://la2dream.com/" target="_blank">https://la2dream.com/</a>
      </p>
      <p>
        When downloading, installing, registering, accessing and any other way of using the Service,
        you fully accept the terms of this Policy and express your consent to the processing of your
        data in a manner and for purposes as described in this document. If you do not agree with
        this Policy, please, refuse to download, install and otherwise use the Service.
      </p>
      <p>
        The Administration may from time to time modify and / or supplement this Policy without
        prior written notice to Users. The new version of this document is located at
        <a href="https://la2dream.com/" target="_blank">https://la2dream.com/</a>
        You need to periodically check the changes and / or additions made to it. If you continue to
        use the Service after making changes to this document, you confirm your agreement with the
        new edition of the Policy.
      </p>
      <p>
        <br /><span style="font-size: small"
          ><strong>1. Purposes of User data processing</strong></span
        >
      </p>
      <p>
        The data is provided by Users in connection with the conclusion with the Administration of
        the User Agreement located at
        <a href="https://la2dream.com/user_agreement" target="_blank"
          >https://la2dream.com/user_agreement</a
        >
      </p>
      <p>
        The data could be obtained on request from Service in connection with the execution of the
        concluded agreements. Cases of the User providing data (without limitation):
      </p>
      <p>
        - When creating an account in the Service
        <a href="https://la2dream.com/" target="_blank">https://la2dream.com/</a>
        and / or on any Server and managing it;<br />- If you participate in testing additional game
        services and / or service updates (if applicable);<br />- When participation in game Events,
        promotions competitions and other similar events;<br />- When sending requests, letters or
        other types of contacts with the Administration of the Service;<br />- When using
        additional, special services inside the Game.
      </p>
      <p>Your data is processed for the following purposes (not limited to):</p>
      <p>
        - Providing the ability to create and then use and manage a User account on the Service;<br />-
        Participation in the Game and access to the game features that are provided for in the
        Gameplay;<br />- Implementing interactions with other Users;<br />- Fixing bugs, developing
        new game features and services;<br />- Interaction with Users for comments on the Service,
        support for Users, sending notifications and other important messages regarding the
        Service;<br />- Accepting, processing and verification of payments;<br />- Providing
        technical support;<br />- Informing about future events related to the Service, their
        updates and other similar events.
      </p>
      <p>
        <br /><span style="font-size: small"
          ><strong>2. Users data, collected and processed by the Service.</strong></span
        >
      </p>
      <p>
        Collected and processed data by the Service may include (but not limited to):<br />- Name,
        nickname (character name);<br />- User ID (login or user ID);<br />- E-mail address;<br />-
        Billing information, etc.
      </p>
      <p>
        <br /><span style="font-size: small"
          ><strong>3. Other data collected and processed by the Service.</strong></span
        >
      </p>
      <p>
        In order to provide quality services, the Service collects and processes additional
        non-personal data. These data include:
      </p>
      <p>
        - Unique identifier of the user device (HWID) and parameters of the used software;<br />-
        Operating system version, device type and hardware identifiers;<br />- Timezone;<br />-
        Information about the user's actions on the Service and directly in the Game (logs of game
        actions, actions on the Service, in-game achievements, time spent in the Game, the time of
        the last entry into the Game, information about the inputs to the Game, etc.).
      </p>
      <p>
        The service can collect information about your IP address and information about the
        geographical location of the device from which you access the Service and the Game in order
        to provide you with the services and information available for the relevant territory on
        which you are located.
      </p>
      <p>
        <br /><span style="font-size: small"><strong>4. Data storage.</strong></span>
      </p>
      <p>
        The data storage period is the period during which the Service and related gaming services
        are provided (for the longest period), unless another period is provided for by applicable
        law.
      </p>
      <p>
        <br /><span style="font-size: small"
          ><strong>5. Uses. Dissemination and transmission of data.</strong></span
        >
      </p>
      <p>
        The Service can combine (merge, splice) and use combined data of Users with other
        information (including other data collected by the Service, as defined in section 4 of this
        Policy) for the provision, management and development of the Game.
      </p>
      <p>In addition, your data can be provided in the following cases:</p>
      <p>
        - When it is necessary to comply with the law, for example, investigating cases of fraud in
        making payments and any other illegal activities;<br />- When there are reasonable
        suspicions of a potential or existing violation of the rights of the Service;
      </p>
      <p>THE ADMINISTRATION DOES NOT TRANSFER YOUR DATA TO THIRD PARTIES.</p>
      <p>
        <br /><span style="font-size: small"><strong>6. Other duties of the Parties.</strong></span>
      </p>
      <p>
        The User is responsible for the completeness and reliability of the data provided by him. In
        case of inconsistencies and / or incorrectness in the data provided by you, they must be
        changed, including by contacting the technical support of the Service, whose contacts are
        listed in the Support section on the Service website -
        <a href="https://la2dream.com/" target="_blank">https://la2dream.com/</a>
      </p>
      <p>
        The Administration reserves the right to delete and / or modify User data, store data that
        is necessary for the purposes of compliance with applicable laws, ensure the security and
        effectiveness of the Service and the Game.
      </p>
      <p>
        <br /><span style="font-size: small"><strong>7. Cookies</strong></span>
      </p>
      <p>
        Cookies are small pieces of information stored by your internet browser on your computer’s
        hard drive. Like most website providers, La2Dream uses cookies to make a link between you
        and the information you have provided to our website and therefore provide you with
        personalized content so that we can give you a better experience when you return. In order
        to benefit from such features you should accept and enable the use of cookies.
      </p>
      <p>
        We would like to point out that if you choose not to accept cookies you will not be able to
        fully use all of the functions of our Service. By activating the cookies feature of your
        browser you agree and consent to the data collection and processing as explained herein.
      </p>
      <p>
        Service also uses so-called authorization cookies, which are intended to store information
        and data submitted by the user on the Service, in order to avoid you from multiple and/or
        repeated submission of such data in a single visit session or longer, if you wish, which
        increases the comfort of using website.
      </p>
      <p>
        <br /><span style="font-size: small"><strong>8. Contacts</strong></span>
      </p>
      <p>
        If you have any questions regarding this Policy or the processing of your data in connection
        with the use of the Game, please contact:
      </p>
      <p>
        Privacy Policy. Merchant business address: Gaujienas 1-32 st., Riga, Latvia<br />Contact
        e-mail: (la2dream.su@gmail.com)<br />Contact phone number: (+37128851563)<br />Company's
        registration name: La 2 Dream<br />Company's place of registration: Latvia<br />Company's
        registered office address: Gaujienas 1-32 st., Riga, Latvia<br />Company's registered
        number: 40203071597
      </p>
      <p>
        Your request must necessarily include the name and other information necessary for the full
        and complete consideration of your request.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyAgreement',
};
</script>

<style scoped>
.title {
  text-align: center;
}

p {
  font-size: 1.2em;
}
</style>
